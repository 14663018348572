<template>
	<v-sheet min-height="300" class="grey lighten-5">
		<v-toolbar flat dense>
			<v-text-field
				style="max-width: 300px"
				class="my-auto"
				v-model="newDomain"
				placeholder="add domain"
				clearable
				hide-details
				dense
				solo
				flat
				append-icon="mdi-plus-circle-outline"
				@click:append="addClaim"
			/>
			<v-divider vertical class="mx-4" />
			<v-spacer />
			<v-btn
				v-if="userStore.claimsModified"
				color="white"
				class="primary"
				text
				:loading="loading"
				@click="updateClaims"
				>update claims</v-btn
			>
			<v-spacer />
		</v-toolbar>
		<v-divider />
		<v-container class="grey lighten-5">
			<v-row v-if="userStore.claims && userStore.claims.accessList"
				><v-col
					cols="12"
					md="4"
					v-for="claim in userStore.claims.accessList"
					:key="claim.domain"
				>
					<UserClaim :claim="claim" />
				</v-col>
			</v-row>
		</v-container>
	</v-sheet>
</template>

<script>
	import { useUserStore } from "@/store/users";
	import UserClaim from "@/views/user/UserClaim";
	export default {
		name: "UserClaims",
		components: { UserClaim },
		data() {
			return {
				loading: false,
				newDomain: "",
			};
		},
		setup() {
			const userStore = useUserStore();
			return { userStore };
		},
		methods: {
			async addClaim() {
				let claim = {
					domain: this.newDomain,
					roles: [],
				};
				if (!this.userStore.claims) {
					this.userStore.claims = {
						accessList: [],
					};
				}
				this.userStore.claims.accessList.push(claim);
				this.newDomain = "";
				this.userStore.setClaimsModified(true);
			},
			async updateClaims() {
				this.loading = true;
				await this.userStore.updateCustomUserClaims();
				this.loading = false;
			},
		},
	};
</script>
