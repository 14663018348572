<template>
	<v-container>
		<v-card rounded="lg" outlined>
			<v-toolbar dense flat>
				<v-toolbar-title
					>User: {{ userStore.user.displayName }}
				</v-toolbar-title>
				<v-spacer />
				<v-icon @click="$router.go(-1)">mdi-close</v-icon>
				<template #extension>
					<v-tabs v-model="tab">
						<v-tab>Info</v-tab>
						<v-tab>Claims</v-tab>
						<v-tab>Activity</v-tab>
					</v-tabs>
				</template>
			</v-toolbar>
			<v-divider />
			<v-tabs-items v-model="tab">
				<v-tab-item><user-info /></v-tab-item>
				<v-tab-item><user-claims /> </v-tab-item>
				<v-tab-item> <user-activity /></v-tab-item>
			</v-tabs-items>
		</v-card>
	</v-container>
</template>

<script>
	import { useUserStore } from "@/store/users";
	import UserInfo from "@/views/user/UserInfo";
	import UserClaims from "@/views/user/UserClaims";
	import UserActivity from "@/views/user/UserActivity";

	export default {
		name: "User",
		components: { UserInfo, UserActivity, UserClaims },
		created() {
			// watch the params of the route to fetch the data again
			this.$watch(
				() => this.$route.params,
				(_new, _old) => {
					if (
						_new &&
						_new.id &&
						_new != _old &&
						this.$route.name === "User"
					) {
						this.userStore.fetchUser(_new.id).then(() => {
							this.userStore.fetchCustomUserClaims();
							// TBD this.userStore.fetchUserActivity()
						});
					}
				},
				// fetch the data when the view is created and the data is
				// already being observed
				{ immediate: true }
			);
		},
		data() {
			return {
				loading: false,
				tab: 0,
			};
		},
		setup() {
			const userStore = useUserStore();
			return { userStore };
		},
		methods: {},
	};
</script>
