<template>
	<v-card outlined>
		<v-card-title class="text-subtitle-2">
			{{ claim.domain }}
			<v-spacer />
			<v-btn plain @click="deleteDomain"
				><v-icon small>mdi-delete</v-icon></v-btn
			>
		</v-card-title>
		<v-divider />
		<v-card-text>
			<v-row
				no-gutters
				v-for="role in claim.roles"
				:key="role"
				align="center"
			>
				<v-col>
					{{ role }}
				</v-col>
				<v-col cols="2">
					<v-btn plain @click="deleteRole(role)"
						><v-icon small>mdi-delete</v-icon></v-btn
					>
				</v-col>
				<v-col cols="1" />
			</v-row>
			<v-row no-gutters align="center">
				<v-col>
					<v-text-field
						v-model="newRole"
						placeholder="add role"
						hide-details="auto"
						dense
						flat
						single-line
					/>
				</v-col>
				<v-col cols="2">
					<v-btn plain
						><v-icon small @click="addRole"
							>mdi-plus-circle-outline</v-icon
						></v-btn
					>
				</v-col>
				<v-col cols="1" />
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
	import { useUserStore } from "@/store/users";
	export default {
		name: "UserClaim",
		props: {
			claim: Object,
		},
		setup() {
			const userStore = useUserStore();
			return { userStore };
		},
		data() {
			return {
				newRole: "",
			};
		},
		methods: {
			addRole() {
				this.claim.roles.push(this.newRole);
				this.newRole = "";
				this.userStore.setClaimsModified(true);
			},
			deleteRole(_role) {
				let _idx = this.claim.roles.indexOf(_role);
				this.claim.roles.splice(_idx, 1);
				this.userStore.setClaimsModified(true);
			},
			deleteDomain() {
				let _idx = this.userStore.claims.accessList.findIndex((_claim) => {
					return _claim.domain === this.claim.domain;
				});
				this.userStore.claims.accessList.splice(_idx, 1);
				this.userStore.setClaimsModified(true);
			},
		},
	};
</script>

