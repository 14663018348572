<template>
	<v-sheet min-height="300" class="grey lighten-5">
		<v-toolbar flat dense>
			<v-btn
				:loading="userStore.loading"
				text
				@click="userStore.sendEmailVerificationLink()"
				>send invitation</v-btn
			>
			<v-spacer />
		</v-toolbar>
		<v-divider />
		<v-container>
			<v-row>
				<v-col>
					<v-text-field
						readonly
						label="Display name"
						:value="userStore.user.displayName"
					/>
				</v-col>
				<v-col>
					<v-text-field
						readonly
						label="Email"
						:value="userStore.user.email"
					/>
				</v-col>
			</v-row>
		</v-container>
	</v-sheet>
</template>

<script>
	import { useUserStore } from "@/store/users";
	export default {
		name: "UserInfo",
		setup() {
			const userStore = useUserStore();
			return { userStore };
		},
	};
</script>
